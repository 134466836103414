<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('seeds_report.org_wise_seed_allocation_report') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
          <b-row>
           <b-col xs="12" sm="12" md="4" lg="4">
              <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="org_id"
              >
              <template v-slot:label>
                {{$t('org_pro.organization')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="search.org_id"
                :options="organizationList"
                id="org_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
            </b-col>
             <b-col xs="12" sm="12" md="4" lg="4">
              <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="fiscal_year_id"
              >
              <template v-slot:label>
                {{$t('warehouse_config.fiscal_year')}}
              </template>
              <b-form-select
              plain
              v-model="search.fiscal_year_id"
              :options="fiscalList"
              id="fiscal_year_id"
              >
                <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4">
              <ValidationProvider name="Measurement" vid="measurement_unit_id" rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="measurement_unit_id"
                >
                <template v-slot:label>
                  {{$t('seedsSeeds.unitName')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="search.measurement_unit_id"
                  :options="unitNameList"
                  id="measurement_unit_id"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                </b-form-group>
              </ValidationProvider>
          </b-col>
           <b-col xs="12" sm="12" md="4" lg="4">
                <ValidationProvider name="Office Type" vid="office_type_id">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="office_type_id"
                  >
                  <template v-slot:label>
                    {{$t('seedsSeeds.office_type')}}
                  </template>
                  <b-form-select
                  plain
                  v-model="search.office_type_id"
                  :options="officeTypeList"
                  id="office_type_id"
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4">
                <ValidationProvider name="Office Name" vid="to_sales_office_id">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="to_sales_office_id"
                  >
                  <template v-slot:label>
                    {{$t('seedsSeeds.office_name')}}
                  </template>
                  <b-form-select
                  plain
                  v-model="search.to_sales_office_id"
                  :options="officeList"
                  id="to_sales_office_id"
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
             <b-col xs="12" sm="12" md="4" lg="4">
                <ValidationProvider name="Production Season" vid="production_season_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="production_season_id"
                      >
                      <template v-slot:label>
                        {{ $t('seedsConfig.productionSeason') }}
                      </template>
                      <v-select name="production_season_id"
                        v-model="search.production_season_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= productionSeasonList
                        :placeholder="$t('globalTrans.select')"
                      />
                  </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4">
                <ValidationProvider name="Crop Type" vid="crop_type_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_type_id"
                      >
                      <template v-slot:label>
                        {{ $t('seedsConfig.cropType') }}
                      </template>
                      <v-select name="crop_type_id"
                        v-model="search.crop_type_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= CropTypeList
                        :placeholder="$t('globalTrans.select')"
                      />
                  </b-form-group>
                </ValidationProvider>
              </b-col>
               <b-col xs="12" sm="12" md="4" lg="4">
                  <ValidationProvider name="Crop Name" vid="crop_name_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="crop_name_id"
                        >
                        <template v-slot:label>
                          {{ $t('seedsConfig.cropName') }}
                        </template>
                        <v-select name="crop_name_id"
                          v-model="search.crop_name_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= cropNameList
                          :placeholder="$t('globalTrans.select')"
                        />
                    </b-form-group>
                  </ValidationProvider>
               </b-col>
             <b-col xs="12" sm="12" md="4" lg="4">
                <ValidationProvider name="Variety" vid="variety_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="variety_id"
                      >
                      <template v-slot:label>
                        {{ $t('seedsConfig.variety') }}
                      </template>
                      <v-select name="variety_id"
                        v-model="search.variety_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= varietyList
                        :placeholder="$t('globalTrans.select')"
                      />
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            <b-col xs="12" sm="12" md="4" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('globalTrans.from_date')"
                label-for="email"
                >
                <template v-slot:label>
                {{$t('globalTrans.from_date')}}
                </template>
                <flat-pickr class="form-control"
                v-model="search.from_date"
                placeholder="Select Date"
                ></flat-pickr>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-cols-lg="4"
                label-for="to_date"
                >
                <template v-slot:label>
                {{$t('globalTrans.to_date')}}
                </template>
                <flat-pickr class="form-control"
                v-model="search.to_date"
                placeholder="Select Date"
                ></flat-pickr>
                </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button class="float-right" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('seeds_report.org_wise_seed_allocation_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <export-excel
                            class="btn btn_add_new"
                            :data="excelData"
                            :title="$t('seeds_report.org_wise_seed_allocation_report')"
                            worksheet="Product Stock Report Sheet"
                            name="filename.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id" :key="search.org_id">
                                    {{ $t('seeds_report.org_wise_seed_allocation_report')}}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                               <b-col>
                                <div class="text-center">
                                    <table style="width:100%;color:black;">
                                        <tr v-if="search.fiscal_year">
                                            <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                        </tr>
                                        <tr v-if="search.measurement_unit">
                                            <td align="right" style="width:45%">{{ $t('seedsSeeds.unitName') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.measurement_unit_bn : search.measurement_unit }}</td>
                                        </tr>
                                        <tr v-if="search.office_type">
                                            <td align="right" style="width:45%">{{ $t('seedsSeeds.office_type') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.office_type_bn : search.office_type }}</td>
                                        </tr>
                                        <tr v-if="search.office_name">
                                            <td align="right" style="width:45%">{{ $t('seedsSeeds.office_name') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.office_name_bn : search.office_name }}</td>
                                        </tr>
                                        <tr v-if="search.season_name">
                                            <td align="right" style="width:45%">{{ $t('seedsConfig.productionSeason') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.season_name_bn : search.season_name }}</td>
                                        </tr>
                                         <tr v-if="search.crop_type_name">
                                            <td align="right" style="width:45%">{{ $t('seedsConfig.cropType') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.crop_type_name_bn : search.crop_type_name }}</td>
                                        </tr>
                                         <tr v-if="search.crop_name">
                                            <td align="right" style="width:45%">{{ $t('seedsConfig.cropName') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.crop_name_bn : search.crop_name }}</td>
                                        </tr>
                                        <tr v-if="search.variety_name">
                                            <td align="right" style="width:45%">{{ $t('seedsConfig.variety') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.variety_name_bn : search.variety_name }}</td>
                                        </tr>
                                         <tr v-if="search.from_date">
                                            <td align="right" style="width:45%">{{ $t('globalTrans.from_date') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{  search.from_date | dateFormat }}</td>
                                        </tr>
                                        <tr v-if="search.to_date">
                                            <td align="right" style="width:45%">{{ $t('globalTrans.to_date') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{  search.to_date | dateFormat }}</td>
                                        </tr>
                                    </table>
                                </div>
                              </b-col>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                 <b-table bordered thead-class="table_head text-center" hover :items="deliveryScheduleList" :fields="columns" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                    <template v-slot:head(index)="data">
                                      <div class="text-right">{{ data.label }}</div>
                                    </template>
                                    <template v-slot:cell(index)="data">
                                      <div class="text-center">{{ $n(data.index + pagination.slOffset) }}</div>
                                    </template>
                                    <template v-slot:cell(allocation_quantity)="data" varient="text-center">
                                      <div class="text-center">{{ $n(data.item.allocation_quantity, { useGrouping: false }) }}</div>
                                    </template>
                                </b-table>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, organizationWiseSeedAllocationReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadSeed.vue'
import ExportPdf from '@/Utils/export-pdf'
import { dateFormat } from '@/Utils/fliter'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      deliveryScheduleList: [],
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 0,
        fiscal_year_id: 0,
        measurement_unit_id: 0,
        production_season_id: '',
        crop_type_id: '',
        crop_name_id: '',
        variety_id: '',
        office_type_id: 0,
        to_sales_office_id: 0,
        from_date: '',
        to_date: ''
      },
      excelData: [],
      currentDate: '',
      organization: [],
      varietyList: [],
      testId: 0,
      rows: [],
      cropNameList: [],
      officeList: [],
      officeTypeList: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    formTitle () {
       return this.$t('warehouse_service_performance.KPI_Report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('seedsConfig.cropType'), class: 'text-center' },
          { label: this.$t('seedsConfig.cropName'), class: 'text-center' },
          { label: this.$t('seedsConfig.variety'), class: 'text-center' },
          { label: this.$t('seeds_report.allocation_qty'), class: 'text-center' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'crop_type_name_bn' },
          { key: 'crop_name_bn' },
          { key: 'variety_name_bn' },
          { key: 'allocation_quantity' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'crop_type_name' },
          { key: 'crop_name' },
          { key: 'variety_name' },
          { key: 'allocation_quantity' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    fiscalList: function () {
      const fiscalyearData = this.$store.state.commonObj.fiscalYearList
      return fiscalyearData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    productionSourceList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSourceList.filter(item => item.status === 1)
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    zoneOfficeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.spcOfficeList.filter(item => item.status === 1)
    },
    SeedClassList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
    },
    CropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    currentFiscalYearId: function () {
      return this.$store.state.SeedsFertilizer.currentFiscalYearId
    },
    unitNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.unitList
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.crop_type_id': function (newVal, oldVal) {
      this.search.crop_name_id = 0
      this.cropNameList = []
      if (newVal) {
        this.cropNameList = this.getCropNameList(newVal)
      }
    },
    'search.crop_name_id': function (newVal, oldVal) {
      this.search.variety_id = 0
      this.varietyList = []
      if (newVal) {
        this.varietyList = this.getVarietyList(newVal)
      }
    },
    'search.org_id': function (newVal, oldVal) {
      this.search.office_type_id = 0
      this.officeTypeList = []
      if (newVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
        this.search.office_type_id = this.$store.state.dataFilters.officeTypeId
      }
    },
    'search.office_type_id': function (newVal, oldVal) {
      this.search.to_sales_office_id = 0
      this.officeList = 0
      if (newVal) {
        this.officeList = this.getOfficeList(newVal)
         this.search.to_sales_office_id = this.$store.state.dataFilters.officeId
      }
    }
  },
  created () {
    this.search.org_id = this.$store.state.dataFilters.orgId
    this.search = Object.assign({}, this.search, { fiscal_year_id: this.currentFiscalYearId })
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    getCropNameList (cropTypeId) {
      return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.crop_type_id === cropTypeId)
    },
    getVarietyList (cropNameId) {
      return this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.crop_name_id === cropNameId)
    },
    searchClick () {
      this.loadData()
    },
    rowClass (item, type) {
        var day = Math.ceil((Math.abs(new Date(item.delivery_date) - new Date())) / (1000 * 60 * 60 * 24))
        if (day <= 7 && type === 'row') {
            return 'table-danger'
        } else {
            return ''
        }
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.organizationData()
      this.getCustomDataSearch()
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === this.search.org_id)

      RestApi.getData(seedFertilizerServiceBaseUrl, reportHeadingList + '/' + this.search.org_id, { org_id: this.search.org_id }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn,
            project_name: orgObject.project_name,
            project_name_bn: orgObject.project_name_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        }
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(seedFertilizerServiceBaseUrl, organizationWiseSeedAllocationReport, params).then(response => {
      if (response.success) {
        if (response.data) {
           this.dataList(response.data)
        }
      } else {
        this.deliveryScheduleList = []
      }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getCustomDataSearch () {
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.search.fiscal_year = fiscalYearObj.text_en
          this.search.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.search.fiscal_year = ''
          this.search.fiscal_year_bn = ''
        }
        const measurementUnitObj = this.$store.state.SeedsFertilizer.commonObj.unitList.find(unit => unit.value === this.search.measurement_unit_id)
        this.search.measurement_unit = typeof measurementUnitObj !== 'undefined' ? measurementUnitObj.text_en : ''
        this.search.measurement_unit_bn = typeof measurementUnitObj !== 'undefined' ? measurementUnitObj.text_bn : ''

        const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(item => item.value === this.search.office_type_id)
          this.search.office_type = typeof officeTypeObj !== 'undefined' ? officeTypeObj.text_en : ''
          this.search.office_type_bn = typeof officeTypeObj !== 'undefined' ? officeTypeObj.text_bn : ''

        const officeNameObj = this.$store.state.commonObj.officeList.find(item => item.value === this.search.to_sales_office_id)
          this.search.office_name = typeof officeNameObj !== 'undefined' ? officeNameObj.text_en : ''
          this.search.office_name_bn = typeof officeNameObj !== 'undefined' ? officeNameObj.text_bn : ''

        const productionSeasonObj = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.find(item => item.value === this.search.production_season_id)
          this.search.season_name = typeof productionSeasonObj !== 'undefined' ? productionSeasonObj.text_en : ''
          this.search.season_name_bn = typeof productionSeasonObj !== 'undefined' ? productionSeasonObj.text_bn : ''

        const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(item => item.value === this.search.crop_type_id)
          this.search.crop_type_name = typeof cropTypeObj !== 'undefined' ? cropTypeObj.text_en : ''
          this.search.crop_type_name_bn = typeof cropTypeObj !== 'undefined' ? cropTypeObj.text_bn : ''

        const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(item => item.value === this.search.crop_name_id)
          this.search.crop_name = typeof cropNameObj !== 'undefined' ? cropNameObj.text_en : ''
          this.search.crop_name_bn = typeof cropNameObj !== 'undefined' ? cropNameObj.text_bn : ''

        const varietyNameObj = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(item => item.value === this.search.variety_id)
          this.search.variety_name = typeof varietyNameObj !== 'undefined' ? varietyNameObj.text_en : ''
          this.search.variety_name_bn = typeof varietyNameObj !== 'undefined' ? varietyNameObj.text_bn : ''
    },
    dataList (data) {
    let tmpCropType = {}
    let tmpCropName = {}
    let tmpVariety = {}
    // var groups = {}
    var listData = data.data.map((item, index) => {
        tmpCropType = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(cropType => cropType.value === item.crop_type_id)
        tmpCropName = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(cropName => cropName.value === item.crop_name_id)
        tmpVariety = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(cropName => cropName.value === item.variety_id)
        const orgData = {
           crop_type_name: tmpCropType !== undefined ? tmpCropType.text_en : '',
           crop_type_name_bn: tmpCropType !== undefined ? tmpCropType.text_bn : '',
           crop_name: tmpCropName !== undefined ? tmpCropName.text_en : '',
           crop_name_bn: tmpCropName !== undefined ? tmpCropName.text_bn : '',
           variety_name: tmpVariety !== undefined ? tmpVariety.text_en : '',
           variety_name_bn: tmpVariety !== undefined ? tmpVariety.text_bn : ''
        }

        return Object.assign({}, item, orgData)
      })
    this.deliveryScheduleList = listData
    var serial = 0
    const rowData = data.data.map((keyItem, index) => {
         serial += 1
         if (this.$i18n.locale === 'en') {
             return {
                'sl ': this.$n(serial),
                'Crop Type': this.deliveryScheduleList[index].crop_type_name,
                'Crop Name': this.deliveryScheduleList[index].crop_name,
                'Variety ': this.deliveryScheduleList[index].variety_name,
                'Total Quanty': keyItem.allocation_quantity
            }
         } else {
             return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'শস্যের ধরণ': this.deliveryScheduleList[index].crop_type_name_bn,
                'শস্যের নাম': this.deliveryScheduleList[index].crop_name_bn,
                'ভ্যারাইটি ': this.deliveryScheduleList[index].variety_name_bn,
                'মোট পরিমাণ ': this.$n(this.deliveryScheduleList[index].allocation_quantity)
            }
         }
     })
    this.excelData = rowData
    },
    getOfficeTypeList (orgId = null) {
        const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
            return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
        return officeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const fromDate = this.$i18n.locale === 'en' ? this.search.from_date : dateFormat(this.search.from_date)
      const toDate = this.$i18n.locale === 'en' ? this.search.to_date : dateFormat(this.search.to_date)
      const fiscalYear = this.$i18n.locale === 'en' ? this.search.fiscal_year : this.search.fiscal_year_bn
      const measurementUnit = this.$i18n.locale === 'en' ? this.search.measurement_unit : this.search.measurement_unit_bn
      const officeType = this.$i18n.locale === 'en' ? this.search.office_type : this.search.office_type_bn
      const officeName = this.$i18n.locale === 'en' ? this.search.office_name : this.search.office_name_bn
      const productionSeason = this.$i18n.locale === 'en' ? this.search.season_name : this.search.season_name_bn
      const cropType = this.$i18n.locale === 'en' ? this.search.crop_type_name : this.search.crop_type_name_bn
      const cropName = this.$i18n.locale === 'en' ? this.search.crop_name : this.search.crop_name_bn
      const variety = this.$i18n.locale === 'en' ? this.search.variety_name : this.search.variety_name_bn
      const header = [
        fromDate ? { text: this.$t('globalTrans.from_date') + ' : ' + fromDate, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        toDate ? { text: this.$t('globalTrans.to_date') + ' : ' + toDate, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        fiscalYear ? { text: this.$t('warehouse_config.fiscal_year') + ' : ' + fiscalYear, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        measurementUnit ? { text: this.$t('seedsSeeds.unitName') + ' : ' + measurementUnit, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        officeType ? { text: this.$t('seedsSeeds.office_type') + ' : ' + officeType, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        officeName ? { text: this.$t('seedsSeeds.office_name') + ' : ' + officeName, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        productionSeason ? { text: this.$t('seedsConfig.productionSeason') + ' : ' + productionSeason, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        cropType ? { text: this.$t('seedsConfig.cropType') + ' : ' + cropType, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        cropName ? { text: this.$t('seedsConfig.cropName') + ' : ' + cropName, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        variety ? { text: this.$t('seedsConfig.variety') + ' : ' + variety, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : ''
      ]

      const labels = [
           { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('seedsConfig.cropType'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('seedsConfig.cropName'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('seedsConfig.variety'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('seeds_report.allocation_qty'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header]
      }
      rowData.unshift(labels)
      const reportTitle = this.$t('seeds_report.org_wise_seed_allocation_report')
      const columnWids = ['*', '*', '*', '*', '*']
      ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_name' : 'crop_type_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'variety_name' : 'variety_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'allocation_quantity' : 'allocation_quantity' }
        ]
      var serial = 0
      const listData = this.deliveryScheduleList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'allocation_quantity') {
                return { text: this.$n(item[keyItem.key]) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
